import { useMutation } from '@apollo/client';
import Layout from '@layouts';
import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import * as React from 'react';
import { FC, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import * as Yup from 'yup';

import { ResetPasswordDocument } from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import TextInput, { PasswordEndPiece } from '@components/form/TextInput';

import { getErrorMessages, getInvalidFields } from '@lib/errors';

type ResetPasswordProps = {};

const ResetPassword: FC<ResetPasswordProps> = () => {
  const [resetPassword] = useMutation(ResetPasswordDocument);
  const [token] = useQueryParam('token', StringParam);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters.')
        .max(128, 'Password must be shorter than 128 characters.')
        .matches(/[a-z]+/, 'Password should include a lowercase letter.')
        .matches(/[A-Z]+/, 'Password should include an uppercase letter.')
        .matches(/[0-9]+/, 'Password should include a number.')
        .required('Required'),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        const response = await resetPassword({
          variables: {
            resetPasswordInput: {
              token: token,
              password: values.password,
            },
          },
        });
        // success
        localStorage.setItem(
          'x-token',
          response.data.resetPassword.accessToken
        );
        localStorage.setItem(
          'x-refresh-token',
          response.data.resetPassword.refreshToken
        );
        await navigate('/');
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        if (errorMessages) alert(errorMessages.join('\n'));
        else console.error('[Reset password page] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Layout url="reset-password" title="Set a new password" disableIndex>
      <div className="flex-1 flex flex-col items-center justify-center">
        <form
          onSubmit={formik.handleSubmit}
          className="sm:w-full sm:max-w-md p-12 shadow-sm rounded-md border-3/2 border-interaction-disabled"
        >
          <p className="font-emp font-bold text-xl">Set a new password</p>
          <TextInput
            formik={formik}
            name="password"
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            placeholder={
              showPassword ? 'eg: mySecretPass123' : 'eg: ***************'
            }
            innerClassName="border-t-0 border-l-0 border-r-0"
            endPiece={<PasswordEndPiece setShowPassword={setShowPassword} />}
          />

          <TextInput
            formik={formik}
            name="passwordConfirmation"
            type={showPassword ? 'text' : 'password'}
            label="Confirm new Password"
            placeholder={
              showPassword ? 'eg: mySecretPass123' : 'eg: ***************'
            }
            className="mt-10"
            innerClassName="border-t-0 border-l-0 border-r-0"
            endPiece={<PasswordEndPiece setShowPassword={setShowPassword} />}
          />

          <div className="flex justify-center mt-12">
            <Button
              variant="outlined"
              className="flex ring-2 ring-interaction-enabled items-center max-w-xs"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Change
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ResetPassword;
